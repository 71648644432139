
  .banner-next,
  .banner-prev {
    top: calc(50% - 20px);
    position: absolute;
    background: white;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
  }
  
  .banner-next {
    right: 24px;
  }
  
  .banner-prev {
    left: 24px;
    transform: scale(-1);
  }
