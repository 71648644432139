@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

body {
  font-family: Inter;
}

@for $i from 0 to 1200 {
  @layer utilities {
    @variants responsive {
      .width-#{$i} {
        width: $i + px;
      }
    }
  }
}

@for $i from 0 to 1200 {
  @layer utilities {
    @variants responsive {
      .min-width-#{$i} {
        min-width: $i + px;
      }
    }
  }
}

@for $i from 0 to 1200 {
  @layer utilities {
    @variants responsive {
      .max-width-#{$i} {
        max-width: $i + px;
      }
    }
  }
}

@for $i from 0 to 1001 {
  @layer utilities {
    @variants responsive {
      .height-#{$i} {
        height: $i + px;
      }
    }
  }
}
.bgGame {
  background-image: url(./public/abc/bgGame.png);
}

.hideScrollChrome::-webkit-scrollbar {
  display: none;
}
.hideScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@for $i from 0 to 1001 {
  .text-stroke-#{$i} {
    -webkit-text-stroke: $i + px white;
    text-shadow: 0px 9px 28px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12);
  }
}

@for $i from 0 to 1200 {
  @layer utilities {
    @variants responsive {
      .min-height-#{$i} {
        min-height: $i + px;
      }
    }
  }
}

@for $i from 0 to 1200 {
  @layer utilities {
    @variants responsive {
      .max-height-#{$i} {
        max-height: $i + px;
      }
    }
  }
}

@for $i from 0 to 1200 {
  @layer utilities {
    @variants responsive {
      .width-screen-#{$i} {
        width: $i + vw;
      }
    }
  }
}

.react-pdf__Document {
  height: 800px; // change height accordingly
}

.week-calendar {
  width: 386px;
  box-shadow: none !important;
  border: none !important;
}
.week-calendar .rc-calendar-tbody > tr:hover .rc-calendar-date {
  background: #ebfaff;
}

.week-calendar
  .rc-calendar-tbody
  > tr:hover
  .rc-calendar-selected-day
  .rc-calendar-date {
  background: #3fc7fa;
}
.week-calendar {
  .rc-calendar-active-week .rc-calendar-date {
    color: white;
    background: #3fc7fa;
  }
}

.week-calendar .week-calendar-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  border-right: 1px solid #ccc;
}

.rc-calendar-range {
  flex-shrink: 0 !important;
  width: 503px !important;
}
